<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23278 aligncenter" src="@/assets/news/10/1.jpg" alt="图片" width="600" height="400"  sizes="(max-width: 600px) 100vw, 600px"></p>
        <p>近期，日本的出入国在留管理厅网站上发布了一项数据显示，2020年日本“在留外国人人数”数量大增，共有30万以上中国地区的申请人拿到了日本“永久居留”。</p>
        <p>往年的数据是不到20万人，但在2020年一年之内足足增长了10万人左右。其中中国大陆地区有283281人，中国台湾地区有22265人。</p>
        <p>&nbsp;</p>
        <p><img loading="lazy" class="wp-image-23279 aligncenter" src="@/assets/news/10/2.png" alt="图片" width="662" height="255"  sizes="(max-width: 662px) 100vw, 662px"></p>
        <p><img loading="lazy" class="wp-image-23280 aligncenter" src="@/assets/news/10/3.png" alt="图片" width="664" height="332"  sizes="(max-width: 664px) 100vw, 664px"></p>
        <p>&nbsp;</p>
        <p>为什么去年有这么多中国申请人拿到“永久居留”呢？</p>
        <p>其中一个主要原因是日本老龄化问题，劳动力不断下降，所以日本开始放宽条件。并在2019年4月，日本正式实施了《外国人才引进法》，计划在未来5年内，向国外招募专业技术人才34.5万人，以补充日本的劳动力市场。</p>
        <p>目前人们所知道的日本项目可以分为“经营管理签证”和“高级人才管理签证”两种，申请人都是通过购入日本房产，并进行经营管理活动，以此来申请日本合法身份。</p>
        <p>&nbsp;</p>
        <p style="text-align: left;"><strong>日本置业优势：</strong></p>
        <p style="text-align: left;">1）永久产权</p>
        <p style="text-align: left;">2）可购买土地</p>
        <p style="text-align: left;">3）可联名/公司/个人购买</p>
        <p style="text-align: left;">4）无公摊，阳台赠送</p>
        <p style="text-align: left;">5）精装交付，无需配备家具家电</p>
        <p style="text-align: left;">6）法律完善，价格透明</p>
        <p style="text-align: left;">7）租赁市场稳定</p>
        <p style="text-align: left;">8）无资产来源要求及背景调查</p>
        <p>&nbsp;</p>
        <p>但关于这两类签证，有别于传统移民项目。</p>
        <p>首先，所谓的“日本移民”与传统意义上取得国别永居权不同。</p>
        <p>当前的日本移民项目主要是以帮助申请人取得“签证进而转为永居”为主，当申请人在满足经营条件及居住要求的情况下，即可申请转为日本永驻居民。</p>
        <p>其次，与欧洲等国家的购房移民项目相比，日本移民所需购入的房产主要用于“企业经营”，申请人是不能用作私人住宅使用的，房产经营所产生的收益将会当做是申请人的经营管理收入，以此满足未来转为日本永驻居民的证明。</p>
        <p>值得注意的是，经营管理项目的经营规模需要超过500万日元以上，投资者每年居住满6个月以上或登陆4-6次，获得日本经营管理签证后全家便可以享受当地福利。</p>
        <p>&nbsp;</p>
        <p><span style="color: #0000ff;"><strong>日本经验管理签证申请条件</strong></span></p>
        <blockquote><p>在日本设立公司，并体现经营管理职能</p>
          <p>有固定经营场所</p>
          <p>注册资本达500万日元以上或雇佣2名职员以上</p></blockquote>
        <p>&nbsp;</p>
        <p>这不仅对于投资者来说是一种新的投资机会，对于未来想长期去日本定居或发展的人来说也是一个不错的选择。</p>
        <p>纵观日本房产民宿，众多选择，跃迁深谙客户需求，只为助您筛选到适合的投资项目，若您对日本民宿投资感兴趣，可拨打免费咨询热线：13764320113，了解更多日本项目。</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "10",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:10,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>